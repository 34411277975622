import { Card, Grid } from '@mui/material';
import styled from '@emotion/styled'
import { APPBAR_HEIGHT } from 'layouts/dashboard/DashboardNavbar';
import { Theme } from '@material-ui/core';
import { forwardRef } from 'react';

const StickyGrid = styled(Grid)(({ theme, sticky }: { theme: Theme; sticky: string }) => ({
  position: sticky === 'true' ? 'sticky' : 'inherit',
  zIndex: 100,
  width: '100%',
  display: 'flex',
  top: `calc(${APPBAR_HEIGHT}px)`,
  pointerEvents: 'none',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '6rem',
  flex: 1,
  maxWidth: '100%'
}));

const StyledCard = styled(Card)(() => ({
  paddingInline: '1rem',
  pointerEvents: 'auto',
  '& > * .MuiButtonBase-root': {
    minWidth: 'max-content'
  },
  '& > *': {
    minWidth: 'max-content'
  },
  overflowX: 'auto',
  height: 'max-content',
  overflowY: 'visible'
}));

export type PageHeaderProps = {
  children: any;
  sticky?: boolean;
  paddingBottom?: string;
};
const PageHeader = (
  { children, sticky = true, paddingBottom = '0' }: PageHeaderProps,
  ref: any
) => {
  return (
    <StickyGrid ref={ref} item sticky={sticky.toString()} style={{ paddingBottom: paddingBottom }}>
      <StyledCard sx={{ overflowX: 'auto' }}>{children}</StyledCard>
    </StickyGrid>
  );
};

export default forwardRef<HTMLDivElement, PageHeaderProps>(PageHeader);
