import releaseConfig from 'releaseConfig';

export const getFunctionsUrl = () => {
  return releaseConfig.functionsUrl.startsWith('#{')
    ? import.meta.env.VITE_FUNCTIONS_URL || 'https://npsworkers-development.azurewebsites.net'
    : releaseConfig.functionsUrl;
};

export const getApiUrl = () => {
  return releaseConfig.apiUrl.startsWith('#{')
    ? import.meta.env.VITE_API_URL || 'https://localhost:5001'
    : releaseConfig.apiUrl;
};

export const getSurveyUrl = () => {
  return releaseConfig.apiUrl.startsWith('#{')
    ? import.meta.env.VITE_SURVEY_URL || 'https://dev.r.nps.today'
    : releaseConfig.pagesUrl;
};

export const getMicrosoftLoginRedirectUri = () => {
  return releaseConfig.apiUrl.startsWith('#{')
    ? import.meta.env.VITE_REDIRECT_URI || 'http://localhost:3000/login/microsoft'
    : releaseConfig.redirectUri;
};
