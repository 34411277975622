import { Link, LinkProps } from '@mui/material';
import styled from '@emotion/styled'

type HoverLinkProps = {
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLSpanElement>)
    | undefined;
  stopPropagation?: boolean;
};

const HoverLink = ({
  onClick: onClickCustom,
  stopPropagation,
  ...rest
}: HoverLinkProps & LinkProps) => {
  const onClick = (event: any) => {
    if (stopPropagation) event.stopPropagation();
    onClickCustom?.(event);
  };

  return <StyledLink {...rest} onClick={onClick} />;
};

const StyledLink = styled(Link)({
  '&:hover': {
    cursor: 'pointer'
  }
});

export default HoverLink;
