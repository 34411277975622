import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import styled from '@emotion/styled'
import { Widget, WidgetProps } from '@rjsf/utils';
import CustomTextField from 'components/util/CustomTextField';
import { IntegerInput } from 'components/util/input/IntegerInput';

const componentDataTestId = 'custom-widget';

const Description = ({ description }: { description?: string }) => {
  return <Typography variant="caption">{description}</Typography>;
};

const customWidgets: { [name: string]: Widget } = {
  TextWidget: (props: WidgetProps) => {
    return props.schema.type === 'integer' ? (
      <IntegerInput
        dataTestId={`${componentDataTestId}-${props.label}`}
        label={props.label}
        onChange={(e) => props.onChange(e)}
        value={props.value}
        allowDecimal={false}
        defaultValue={null}
      />
    ) : (
      <WidgetWrapper>
        <CustomTextField
          dataTestId={`${componentDataTestId}-${props.label}`}
          fullWidth
          type={props.schema.format === 'integer' ? 'number' : props.schema.format}
          value={props.value ?? ''}
          label={props.label}
          placeholder={props.placeholder}
          required={props.required}
          disabled={props.disabled}
          onChange={(event) => {
            props.onChange(event.target.value);
          }}
          onBlur={(event) => props.onBlur(props.id, event.target.value)}
          error={Boolean(props.rawErrors)}
          helperText={props.rawErrors?.join(' - ')}
        />
      </WidgetWrapper>
    );
  },
  SelectWidget: (props: WidgetProps) => {
    const selectLabelId = `select-label-${props.id}`;

    const options = props.options.enumOptions;
    return (
      <WidgetWrapper>
        <Description description={props.schema.description} />
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id={selectLabelId} required={props.required}>
            {props.label}
          </InputLabel>
          <Select
            fullWidth
            required={props.required}
            value={props.value ?? ''}
            onChange={(event: any) => props.onChange(event.target.value)}
            labelId={selectLabelId}
            label={'Answer type'}
          >
            {options?.map((option: any, index: number) => (
              <MenuItem key={index} value={option?.value}>
                {option?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </WidgetWrapper>
    );
  },
  CheckboxWidget: (props: WidgetProps) => {
    return (
      <WidgetWrapper>
        <Description description={props.schema.description} />
        <FormControlLabel
          label={props.label}
          control={
            <Checkbox
              required={props.required}
              checked={props.value || props.defaultValue || false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.onChange(event.target.checked);
              }}
            />
          }
        />
      </WidgetWrapper>
    );
  }
};

export default customWidgets;

const WidgetWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});
