import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { MIconButton } from 'components/@material-extend';
import Icon from 'components/icons/Icon';
import MoreMenuButton from 'components/util/MoreMenuButton';
import DisabledIcon from 'components/util/Status/DisabledIcon';
import EnabledIcon from 'components/util/Status/EnabledIcon';
import { styled } from '@mui/material/styles';
import { APIKey } from '../../../../@types/organization';
import useDisplayMessage from 'components/messages/useMessage';
import useCustomDispatch from 'redux/dispatch';
import { deleteAPIKey, editAPIKey } from 'redux/slices/organization';
import CustomTextField from 'components/util/CustomTextField';
import CustomButton from 'components/util/CustomButton';
import { FunctionComponent, useState } from 'react';
import { useTranslator } from 'translation/useTranslator';

const ApiKeyDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& > :nth-child(2)': { opacity: 0 },
  '&:hover': {
    cursor: 'pointer',
    '& > :nth-child(2)': { opacity: 1 }
  }
});

interface ApiKeyRowProps {
  apiKey: APIKey;
}

export const ApiKeyRow: FunctionComponent<ApiKeyRowProps> = ({ apiKey }) => {
  const translator = useTranslator();
  const customDispatch = useCustomDispatch();
  const displayMessage = useDisplayMessage();
  const [editOpen, setEditOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState<APIKey>(apiKey);
  const [displayValue, setDisplayValue] = useState(false);

  return (
    <>
      <Stack direction="row" alignItems="center" key={apiKey.id} gap={2}>
        <Box sx={{ flexGrow: 1, ml: 2, minWidth: 100 }}>
          <Stack direction="row" alignItems="center" gap={1}>
            {apiKey.active ? (
              <EnabledIcon tooltip={translator.active()} size="16px" />
            ) : (
              <DisabledIcon tooltip={translator.revoked()} size="16px" />
            )}
            <Typography variant="subtitle2" noWrap>
              {apiKey.name}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" key={apiKey.id}>
            <ApiKeyDiv
              onClick={() => {
                navigator.clipboard.writeText(apiKey.key);
                displayMessage.success({
                  message: { title: translator.apiKeyCopiedToClipboard(apiKey.name) }
                });
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {displayValue ? apiKey.key : '****************'}
              </Typography>
              <Tooltip title={translator.copyApiKey()}>
                <MIconButton size="small">
                  <Icon type="copy" isText />
                </MIconButton>
              </Tooltip>
            </ApiKeyDiv>
          </Stack>
        </Box>
        <IconButton
          aria-label="toggle api key visibility"
          onClick={() => setDisplayValue((prevState) => !prevState)}
        >
          {displayValue ? <Icon type="eyeon" /> : <Icon type="eyeoff" />}
        </IconButton>
        <MoreMenuButton
          itemType="api key"
          onEdit={() => {
            setSelectedApiKey(apiKey);
            setEditOpen(true);
          }}
          onEditAccess="ManagementEdit"
          onDelete={() => {
            customDispatch({ action: deleteAPIKey, actionParameters: apiKey.id });
          }}
          onDeleteAccess="ManagementDelete"
          deleteAreYouSureContent={translator.areYouSureYouWantToDeleteThisApiKey(apiKey.name)}
        />
      </Stack>
      <Dialog open={editOpen} onClose={() => setEditOpen(false)} fullWidth>
        <DialogTitle>{translator.editApiKey()}</DialogTitle>
        <DialogContent>
          <Stack>
            <CustomTextField
              dataTestId="edit-api-key-dialog-name"
              value={selectedApiKey.name}
              onChange={(event) =>
                setSelectedApiKey({ ...selectedApiKey, name: event.target.value })
              }
              label={translator.name()}
              margin={'normal'}
              color={'primary'}
              required
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedApiKey.active}
                    value={selectedApiKey.active}
                    onChange={(e) =>
                      setSelectedApiKey({ ...selectedApiKey, active: e.target.checked })
                    }
                  />
                }
                label={translator.active()}
              />
              <FormHelperText>
                {apiKey.active ? translator.thisApiKeyIsActive() : translator.thisApiKeyIsRevoked()}
              </FormHelperText>
            </FormGroup>
          </Stack>
        </DialogContent>
        <DialogActions>
          <CustomButton dataTestId="edit-api-key-dialog-cancel" onClick={() => setEditOpen(false)}>
            {translator.cancel()}
          </CustomButton>
          <CustomButton
            dataTestId="edit-api-key-dialog-edit"
            onClick={() => {
              customDispatch({
                action: editAPIKey,
                actionParameters: { apiKey: selectedApiKey, newName: selectedApiKey.name },
                onSuccess: () => {
                  setEditOpen(false);
                }
              });
            }}
            autoFocus
          >
            {translator.edit()}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
