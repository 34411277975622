import { useCallback, useEffect, useState } from 'react';
import { translator as translatorDanish } from './generated/da';
import { translator as translatorEnglish } from './generated/en';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'contexts/LanguageContext';
import { ITranslator } from './generated/translationInterface';

export let translator: ITranslator | undefined;

export const useTranslator = () => {
  const userLanguage = useLanguage();
  const { t } = useTranslation(userLanguage.language);

  const getTranslatorForLanguage = useCallback(() => {
    if (userLanguage.language === 'da') {
      return translatorDanish(t);
    } else if (userLanguage.language === 'en') {
      return translatorEnglish(t);
    }
    return translatorDanish(t);
  }, [userLanguage.language, t]);
  const [currentTranslator, setCurrentTranslator] = useState(getTranslatorForLanguage());

  useEffect(() => {
    //set translator for use in outside components
    translator = getTranslatorForLanguage();

    //set translator for use in components
    setCurrentTranslator(translator);
  }, [userLanguage.language, getTranslatorForLanguage]);

  return currentTranslator;
};
