import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { forwardRef, useEffect, useCallback, ReactNode, RefObject } from 'react';
// material
import { Box, BoxProps, Divider, LinearProgress } from '@mui/material';
// utils
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import HeaderBreadcrumbs from './HeaderBreadcrumbs';
import { Container } from '@mui/material';
import PageHeader from './PageHeader';
import { TLink } from 'components/@material-extend/MBreadcrumbs';
import { Stack } from '@mui/material';
import styled from '@emotion/styled'
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import useSettings from 'hooks/useSettings';

// ----------------------------------------------------------------------

export type HeaderElement = {
  content: JSX.Element;
  align?: 'left' | 'right';
};

export type HeaderElementGroup = {
  elements: HeaderElement[];
};

export type StickyHeaderContent = JSX.Element | false | undefined | null;
interface PageProps extends BoxProps {
  disableHeader?: boolean;
  children: ReactNode;
  title?: string;
  pageIcon?: JSX.Element;
  prevLinks?: TLink[];
  stickyHeaderContent?: StickyHeaderContent[][];
  isLoading?: boolean;
  getPageHeaderRef?: () => RefObject<HTMLDivElement>;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      disableHeader,
      children,
      title = '',
      pageIcon,
      prevLinks,
      stickyHeaderContent,
      isLoading = false,
      getPageHeaderRef,
      ...other
    },
    ref
  ) => {
    const theme = useTheme();
    const { themeStretch } = useSettings();
    const IsMediumScreenWidth = useMediaQuery(theme.breakpoints.down('md'));

    //Send trackPageView data on first render, to Azure ApplicationInsights
    const appInsights = useAppInsightsContext();
    useEffect(() => {
      appInsights.trackPageView({ name: title });
    }, [title, appInsights]);

    const headerBreadcrumbsLinks = prevLinks
      ? [
          ...prevLinks,
          {
            name: title
          }
        ]
      : [
          {
            name: title
          }
        ];

    return (
      <Box
        ref={ref}
        {...other}
        style={{ display: 'flex', margin: 'auto', maxWidth: themeStretch ? '80rem' : '100%' }}
      >
        <Helmet>
          <title>{`${title} | nps.today`}</title>
        </Helmet>
        <Container
          style={{
            maxWidth: 'inherit',
            display: 'flex',
            flexWrap: 'wrap',
            paddingInline: IsMediumScreenWidth ? '.5rem' : '1rem'
          }}
        >
          {!disableHeader && (
            <>
              <div style={{ display: 'flex', flex: 1 }}>
                <HeaderBreadcrumbs
                  heading={title}
                  headingIcon={pageIcon}
                  links={headerBreadcrumbsLinks}
                />
              </div>
              {stickyHeaderContent && (
                <PageHeader>
                  <Stack
                    alignItems="center"
                    style={{ height: '100%', overflow: 'wrap' }}
                    direction={{ xs: 'row' }}
                  >
                    <Box
                      style={{
                        flex: 1,
                        height: 'calc(100% + 1rem)',
                        display: 'flex',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        gap: '1rem',
                        justifyContent: 'end'
                      }}
                    >
                      {stickyHeaderContent?.map((contentGroup, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'min-content',
                            gap: '1rem'
                          }}
                        >
                          {index > 0 && <Divider orientation="vertical" />}
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 'min-content',
                              gap: '1rem'
                            }}
                          >
                            {contentGroup
                              .filter((content) => content)
                              ?.map((content, index) => (
                                <HeaderContentWrapper key={index}>{content}</HeaderContentWrapper>
                              ))}
                          </div>
                        </div>
                      ))}
                    </Box>
                  </Stack>
                </PageHeader>
              )}
              <Box p={1} style={{ width: '100%' }} />
            </>
          )}
          <div style={{ width: '100%' }}>{isLoading ? <LinearProgress /> : children}</div>
        </Container>
      </Box>
    );
  }
);

export default Page;

const HeaderContentWrapper = styled('div')({
  marginBlock: '1rem',
  '& button': {
    boxShadow: 'none'
  }
});
