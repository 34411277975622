import Account from './account.svg?react';
import Add from './add.svg?react';
import Beta from './beta.svg?react';
import Calendar from './calendar.svg?react';
import CampaignActive from './campaign-active.svg?react';
import CampaignClosed from './campaign-closed.svg?react';
import Campaigns from './campaigns.svg?react';
import Clear from './clear.svg?react';
import Clock from './clock.svg?react';
import Copy from './copy.svg?react';
import Companies from './companies.svg?react';
import Customize from './customize.svg?react';
import DarkMode from './dark-mode.svg?react';
import Dashboard from './dashboard.svg?react';
import DataRetention from './data-retention.svg?react';
import Delete from './delete.svg?react';
import Disable from './disable.svg?react';
import Disabled from './disabled.svg?react';
import Display from './display.svg?react';
import Download from './download.svg?react';
import Edit from './edit.svg?react';
import Email from './email.svg?react';
import Employees from './employees.svg?react';
import Enable from './enable.svg?react';
import Enabled from './enabled.svg?react';
import Equal from './equal.svg?react';
import ExpandDown from './expand-down.svg?react';
import EyeOff from './eye-off.svg?react';
import EyeOn from './eye-on.svg?react';
import Flag from './flag.svg?react';
import ForwardEmail from './forward-email.svg?react';
import FullScreen from './full-screen.svg?react';
import General from './general.svg?react';
import Information from './information.svg?react';
import IntegrationStore from './integration-store.svg?react';
import IVR from './ivr.svg?react';
import Key from './key.svg?react';
import LightMode from './light-mode.svg?react';
import Link from './link.svg?react';
import License from './license.svg?react';
import Logo from './logo.svg?react';
import Manual from './manual.svg?react';
import MoreMenu from './more-menu.svg?react';
import NavigationNext from './navigation-next.svg?react';
import NavigationPrevious from './navigation-previous.svg?react';
import NotReload from './not-reload.svg?react';
import Note from './note.svg?react';
import OrderAndUsage from './order-and-usage.svg?react';
import Organizations from './organizations.svg?react';
import Overview from './overview.svg?react';
import PGap from './p-gap.svg?react';
import Password from './password.svg?react';
import ParagraphCenterAlign from './paragraph-center-align.svg?react';
import ParagraphLeftAlign from './paragraph-left-align.svg?react';
import ParagraphRightAlign from './paragraph-right-align.svg?react';
import Person from './person.svg?react';
import Phone from './phone.svg?react';
import Providers from './providers.svg?react';
import Question from './question.svg?react';
import QuestionWithoutCircle from './question-without-circle.svg?react';
import Reload from './reload.svg?react';
import Reports from './reports.svg?react';
import ResendSurvey from './resend-survey.svg?react';
import Reset from './reset.svg?react';
import Respondents from './respondents.svg?react';
import Reviews from './reviews.svg?react';
import Search from './search.svg?react';
import Security from './security.svg?react';
import Senders from './senders.svg?react';
import Settings from './settings.svg?react';
import SMS from './sms.svg?react';
import SuperAdmin from './super-admin.svg?react';
import Swap from './swap.svg?react';
import Template from './template.svg?react';
import ThrottlingGroup from './throttling-group.svg?react';
import Unsubscribe from './unsubscribe.svg?react';
import User from './user.svg?react';
import Users from './users.svg?react';
import Connection from './connection.svg?react';
import CurlyBrackets from './curly-brackets.svg?react';
import Success from './success.svg?react';
import Warning from './warning.svg?react';
import Error from './error.svg?react';
import Menu from './menu.svg?react';
import Pin from './pin.svg?react';
import NpsNotification from './nps-notification.svg?react';
import ArrowRight from './arrow-right.svg?react';
import ProductConfigurations from './product-configurations.svg?react';
import QrCode from './qr-code.svg?react';
import Robot from './robot.svg?react';

import { IconType } from '../../@types/icons';

const Icons: {
  [key in IconType]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
} = {
  account: Account,
  add: Add,
  arrowright: ArrowRight,
  beta: Beta,
  calendar: Calendar,
  campaignactive: CampaignActive,
  campaignclosed: CampaignClosed,
  campaigns: Campaigns,
  clear: Clear,
  clock: Clock,
  copy: Copy,
  companies: Companies,
  customize: Customize,
  darkmode: DarkMode,
  dashboard: Dashboard,
  dataretention: DataRetention,
  delete: Delete,
  disable: Disable,
  disabled: Disabled,
  display: Display,
  download: Download,
  edit: Edit,
  email: Email,
  employees: Employees,
  enable: Enable,
  enabled: Enabled,
  expanddown: ExpandDown,
  eyeoff: EyeOff,
  eyeon: EyeOn,
  flag: Flag,
  forwardemail: ForwardEmail,
  fullscreen: FullScreen,
  general: General,
  information: Information,
  integrationstore: IntegrationStore,
  ivr: IVR,
  key: Key,
  license: License,
  lightmode: LightMode,
  link: Link,
  logo: Logo,
  manual: Manual,
  moremenu: MoreMenu,
  notreload: NotReload,
  note: Note,
  npsnotification: NpsNotification,
  orderandusage: OrderAndUsage,
  organizations: Organizations,
  overview: Overview,
  pgap: PGap,
  paragraphCenterAlign: ParagraphCenterAlign,
  paragraphLeftAlign: ParagraphLeftAlign,
  paragraphRightAlign: ParagraphRightAlign,
  password: Password,
  person: Person,
  phone: Phone,
  providers: Providers,
  question: Question,
  questionwithoutcircle: QuestionWithoutCircle,
  reload: Reload,
  reports: Reports,
  resendsurvey: ResendSurvey,
  reset: Reset,
  respondents: Respondents,
  reviews: Reviews,
  search: Search,
  security: Security,
  senders: Senders,
  settings: Settings,
  sms: SMS,
  superadmin: SuperAdmin,
  template: Template,
  throttlinggroup: ThrottlingGroup,
  unsubscribe: Unsubscribe,
  user: User,
  users: Users,
  equal: Equal,
  navigationnext: NavigationNext,
  navigationprevious: NavigationPrevious,
  swap: Swap,
  connection: Connection,
  curlybrackets: CurlyBrackets,
  success: Success,
  warning: Warning,
  error: Error,
  menu: Menu,
  pin: Pin,
  productconfigurations: ProductConfigurations,
  qrcode: QrCode,
  robot: Robot
};

export default Icons;
