import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'redux/store';
import axios from '../../utils/axios';
import {
  Integration,
  IntegrationsState,
  IntegrationType,
  UpdateIntegration
} from '../../@types/integrations';

const initialState: IntegrationsState = {
  isLoading: {},
  integrations: [],
  currentIntegration: null,
  hasError: false
};

export const getIntegrationLoadingIndex = (integrationId?: number) => {
  return `integration${integrationId}`;
};

const slice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    startGetIntegrations(state) {
      state.isLoading.integrations = true;
    },
    getIntegrationsSuccess(state, action) {
      state.integrations = action.payload;
      state.isLoading.integrations = false;
      state.hasError = false;
    },
    getIntegrationsError(state) {
      state.integrations = [];
      state.isLoading.integrations = false;
      state.hasError = true;
    },
    startGetCurrentIntegration(state) {
      state.isLoading.currentIntegration = true;
    },
    getCurrentIntegrationSuccess(state, action) {
      state.currentIntegration = action.payload;
      state.isLoading.currentIntegration = false;
      state.hasError = false;
    },
    getCurrentIntegrationError(state) {
      state.currentIntegration = null;
      state.isLoading.currentIntegration = false;
      state.hasError = true;
    },
    startRemoveIntegration(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = true;
    },
    removeIntegrationSuccess(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = false;
    },
    removeIntegrationError(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = false;
    },
    startAddIntegration(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = true;
    },
    addIntegrationSuccess(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = false;
    },
    addIntegrationError(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = false;
    },
    startUpdateIntegrationConfiguration(state) {
      state.isLoading.updateConfiguration = true;
    },
    updateIntegrationConfigurationSuccess(state) {
      state.isLoading.updateConfiguration = false;
    },
    updateIntegrationConfigurationError(state) {
      state.isLoading.updateConfiguration = false;
    },
    startUpdateIntegration(state) {
      state.isLoading.updateIntegration = true;
    },
    updateIntegrationSuccess(state) {
      state.isLoading.updatIntegration = false;
    },
    updateIntegrationError(state) {
      state.isLoading.updateIntegration = false;
    }
  }
});

export default slice.reducer;

export const getIntegrations = () => {
  return async () => {
    dispatch(slice.actions.startGetIntegrations());
    try {
      const response = await axios.get(`/integrations`);
      dispatch(slice.actions.getIntegrationsSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched integrations'
      });
    } catch (error: any) {
      dispatch(slice.actions.getIntegrationsError());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch integrations`
      });
    }
  };
};

type GetIntegrationProps = {
  integrationId: IntegrationType;
};
export const getIntegration = ({ integrationId }: GetIntegrationProps) => {
  return async () => {
    dispatch(slice.actions.startGetCurrentIntegration());
    try {
      const response = await axios.get(`/integrations/${integrationId}`);
      dispatch(slice.actions.getCurrentIntegrationSuccess(response.data));
      return await Promise.resolve({
        defaultSuccessMessage: 'Fetched integration'
      });
    } catch (error: any) {
      dispatch(slice.actions.getCurrentIntegrationError());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch integration`
      });
    }
  };
};

type UpdateIntegrationConfigurationProps = {
  integrationId: IntegrationType;
  configuration: any;
};
export const updateIntegrationConfiguration = ({
  integrationId,
  configuration
}: UpdateIntegrationConfigurationProps) => {
  return async () => {
    dispatch(slice.actions.startUpdateIntegrationConfiguration());
    try {
      await axios.put('/integrations/configurations', {
        integrationId,
        configuration
      });
      dispatch(slice.actions.updateIntegrationConfigurationSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: 'Updated integration configuration'
      });
    } catch (error: any) {
      dispatch(slice.actions.updateIntegrationConfigurationError());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not update integration configuration`
      });
    }
  };
};

type UpdateIntegrationProps = {
  integration: UpdateIntegration;
};
export const updateIntegration = ({ integration }: UpdateIntegrationProps) => {
  return async () => {
    dispatch(slice.actions.startUpdateIntegration());
    try {
      await axios.put('/integrations', integration);
      dispatch(slice.actions.updateIntegrationSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: 'Updated integration'
      });
    } catch (error: any) {
      dispatch(slice.actions.updateIntegrationError());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not update integration`
      });
    }
  };
};

export const removeIntegration = (integration: Integration) => {
  const integrationId = integration.id;
  return async () => {
    dispatch(slice.actions.startRemoveIntegration(integrationId));
    try {
      await axios.delete(`/integrations/${integrationId}`);
      dispatch(slice.actions.removeIntegrationSuccess(integrationId));
      return await Promise.resolve({
        defaultSuccessMessage: `${integration?.name} integration removed`
      });
    } catch (error: any) {
      dispatch(slice.actions.removeIntegrationError(integrationId));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not remove integration`
      });
    }
  };
};

export const addIntegration = (integration: Integration) => {
  const integrationId = integration.id;
  return async () => {
    dispatch(slice.actions.startAddIntegration(integrationId));
    try {
      await axios.post(`/integrations/${integrationId}`);
      dispatch(slice.actions.addIntegrationSuccess(integrationId));
      return await Promise.resolve({
        defaultSuccessMessage: `${integration?.name} integration added`
      });
    } catch (error: any) {
      dispatch(slice.actions.addIntegrationError(integrationId));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not add integration`
      });
    }
  };
};
