import jwtDecode from 'jwt-decode';
import axios, { axiosEtrack1Instance } from './axios';

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getUserRole = (accessToken: string) => {
  if (!accessToken) {
    return undefined;
  }
  const decoded = jwtDecode<{ role: string }>(accessToken);
  return decoded.role;
};

const getApplicationClaims = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode<{
    Applications: string[];
    features: string[];
    management: string[];
    actions: string[];
  }>(accessToken);

  //if there is only one claim, the value is a string, instead of an array of strings
  const getClaimArray = (claimType: string, claims?: string[] | string) => {
    let claimArray = typeof claims === 'string' ? [claims] : !claims ? [] : claims;
    claimArray = claimArray.map((claim) => `${claimType}_${claim.toLowerCase()}`);
    return claimArray;
  };
  const Applications = getClaimArray('applications', decoded?.Applications);
  const features = getClaimArray('features', decoded?.features);
  const management = getClaimArray('management', decoded?.management);
  const actions = getClaimArray('actions', decoded?.actions);

  //create one array with claims from both Applications, features, and management
  const allClaims = [...Applications, ...features, ...management, ...actions];
  //remove any duplicates
  const allClaimsNoDublicates = Array.from(new Set(allClaims));
  return allClaimsNoDublicates;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosEtrack1Instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    delete axiosEtrack1Instance.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, getApplicationClaims, getUserRole };
