import React from 'react';
import { FormControlLabel, FormHelperText, Switch, SwitchProps } from '@mui/material';
import { FormControlLabelProps } from '@mui/material';

type CustomSwitchProps = {
  label?: FormControlLabelProps['label'];
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  fullWidth?: boolean;
  helperText?: string;
  switchProps: SwitchProps;
};

const CustomSwitch = React.forwardRef<HTMLButtonElement, CustomSwitchProps>(
  ({ label, labelPlacement, fullWidth, helperText, switchProps }, ref) => {
    if (label) {
      const extraSwitchStyle = switchProps.style ?? {};

      return (
        <>
          <FormControlLabel
            style={{ pointerEvents: 'none' }}
            control={
              <Switch
                {...switchProps}
                checked={Boolean(switchProps.checked)}
                ref={ref}
                style={{ ...extraSwitchStyle, pointerEvents: 'auto' }}
              />
            }
            label={label}
            labelPlacement={labelPlacement}
            sx={fullWidth ? { mx: 0, width: '100%', justifyContent: 'space-between' } : {}}
          />
          {helperText && <FormHelperText style={{ margin: 0 }}>{helperText}</FormHelperText>}
        </>
      );
    }

    return <Switch {...switchProps} ref={ref} />;
  }
);

export default CustomSwitch;
