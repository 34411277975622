import { translator } from 'translation/useTranslator';
import { YupTestConfig, YupTestFunction, YupContext } from './yupUtils';

import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneNumberValid = (
  phoneNumber: string | undefined | null,
  initialValue?: string
): boolean => {
  //no number is valid
  if (!phoneNumber) {
    return true;
  }

  //if there is an initial value, and it has not been changed, accept the phone number
  if (initialValue && initialValue === phoneNumber) {
    return true;
  }

  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
    return phoneUtil.isValidNumber(number);
  } catch (e) {
    return false;
  }
};

export default isPhoneNumberValid;

export const getPhoneNumberYupTest = (): YupTestConfig => {
  const testFunction: YupTestFunction = function (this, phoneNumber) {
    const context = this.options.context as YupContext;
    const initialValue = context?.[`initial${this.path}`] ?? '';
    return isPhoneNumberValid(phoneNumber, initialValue);
  };

  return {
    name: 'isPhoneNumberValid',
    message: translator?.invalidPhonuNumber() ?? '',
    test: testFunction
  };
};
