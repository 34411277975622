import { getMicrosoftLoginRedirectUri } from './utils/urls/getUrls';
import releaseConfig from './releaseConfig';

export const isNotProduction = releaseConfig.environment !== 'production';
export const isDev =
  releaseConfig.apiUrl.startsWith('#{') || releaseConfig.environment === 'development';

export const msalConfig = {
  auth: {
    clientId: 'c56820cf-9d7d-4238-96ac-96f46cbae060',
    authority: 'https://login.microsoftonline.com/organizations', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: getMicrosoftLoginRedirectUri(),
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['api://c56820cf-9d7d-4238-96ac-96f46cbae060/user_impersonation']
};
