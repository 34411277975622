import {
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { createAPIKey } from '../../../../redux/slices/organization';
import { RootState, useSelector } from '../../../../redux/store';
import useCustomDispatch from 'redux/dispatch';
import CustomButton from 'components/util/CustomButton';
import AccessGuard from 'guards/AccessGuard';
import CustomTextField from 'components/util/CustomTextField';
import { ApiKeyRow } from './ApiKeyRow';
import { useTranslator } from 'translation/useTranslator';

export default function APIKeys() {
  const translator = useTranslator();
  const customDispatch = useCustomDispatch();
  const { apiKeys } = useSelector((state: RootState) => state.organization);
  const [createOpen, setCreateOpen] = useState(false);
  const [name, setName] = useState('');

  return (
    <Card sx={{ py: 3, mt: 3, width: '100%' }}>
      <CardHeader
        title={translator.apiKeys()}
        subheader={translator.youHaveXApiKeys(`${apiKeys.length}`)}
        action={
          <AccessGuard access="ManagementCreate">
            <CustomButton
              dataTestId="add-api-key"
              variant="contained"
              onClick={() => setCreateOpen(true)}
            >
              {translator.addApiKey()}
            </CustomButton>
          </AccessGuard>
        }
      />
      <Dialog open={createOpen} onClose={() => setCreateOpen(false)} fullWidth>
        <DialogTitle>{translator.addApiKey()}</DialogTitle>
        <DialogContent>
          <Typography>{translator.pleaseGiveTheApiKeyAName()}</Typography>
          <CustomTextField
            dataTestId="add-api-key-dialog-name"
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
            label={translator.name()}
            margin={'normal'}
            color={'primary'}
            required
          />
        </DialogContent>
        <DialogActions>
          <CustomButton dataTestId="add-api-key-dialog-cancel" onClick={() => setCreateOpen(false)}>
            {translator.cancel()}
          </CustomButton>
          <CustomButton
            dataTestId="add-api-key-dialog-create"
            disabled={name?.length <= 0}
            onClick={() => {
              if (!name) return;
              customDispatch({
                action: createAPIKey,
                actionParameters: name,
                onSuccess: () => {
                  setCreateOpen(false);
                }
              });
            }}
            autoFocus
            variant={'contained'}
          >
            {translator.create()}
          </CustomButton>
        </DialogActions>
      </Dialog>
      <Stack spacing={2} sx={{ p: 3 }}>
        <ul style={{ gap: '1rem' }}>
          {apiKeys.map((apiKey, index) => (
            <div key={index}>
              <li key={index} style={{ listStyle: 'none', gap: '1rem' }}>
                <ApiKeyRow apiKey={apiKey} />
              </li>
              {apiKeys.length - 1 > index && <Divider style={{ margin: '.5rem' }} />}
            </div>
          ))}
        </ul>
      </Stack>
    </Card>
  );
}
